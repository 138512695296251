import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import Cookies from 'js-cookie';
import URL from './URL';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import { DebounceInput } from 'react-debounce-input';
import Pagination from "react-js-pagination";

const axios = require('axios').default;
const loading_icon = <FontAwesomeIcon icon={faCircleNotch} size="lg" />

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var ACCT = Cookies.get('ACCT');
var FN = Cookies.get('FN');
var U = Cookies.get('U');

class Tasks extends React.Component {
    constructor(props) {
        super(props)
        this.state = { "tasks": {}, "priority": "All", "show": "r", "to": "", "page_number": 0, "before_sort": "n", "search_term": "", "before_sort_check": false, "task_selected_bool": false, "selected_task": {}, "selected_task_id": "", "selected_task_update_bool": false, "selected_task_update": {}, "selected_task_close_bool": false, "selected_task_close": {}, response: {}, "remarks": "", "selected_task_info_update": "", "edittasks_images": [], "edittasks_images_extra": [] }

        this.Tasks = this.Tasks.bind(this);
        this.get_tasks = this.get_tasks.bind(this);
        this.SelectedTask = this.SelectedTask.bind(this);
        this.handle_task_select = this.handle_task_select.bind(this);
        this.get_single_task = this.get_single_task.bind(this);
        this.handle_search = this.handle_search.bind(this);

        this.get_tasks();
        this.initTimer();
    }

    initTimer() {
        this.timer = setInterval(() => this.get_tasks(), 5000);
    }
    deinitTimer() {
        clearInterval(this.timer)
        this.timer = null; // here...
    }
    componentDidMount() {
        // this.initTimer()
    }
    componentWillUnmount() {
        this.deinitTimer()
    }

    get_tasks = (e) => {
        const that = this;
        var params = { page_number: this.state.page_number, s: this.state.show }

        if (this.state.priority.length > 0) {
            params["p"] = this.state.priority
        } else {
            delete params["p"];
        }
        if (this.state.to.length > 0) {
            params["t"] = this.state.to
        } else {
            delete params["t"];
        }
        if (this.state.search_term.length > 0) {
            params["st"] = this.state.search_term
        } else {
            delete params["st"];
        }

        axios.get(URL + "t", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                if (response["data"]["payload"] === "No Session.") {
                    Cookies.remove('ACCT');
                    Cookies.remove('U');
                    Cookies.remove('TP');
                    Cookies.remove('FN');
                    window.location.reload();
                }
                that.setState({ "tasks": response["data"]["payload"] })
            })
    }

    get_single_task = (e) => {
        const that = this;
        // console.log(this.state.selected_task_id)
        var params = { "_id": this.state.selected_task_id }
        axios.get(URL + "t", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ "selected_task": response["data"]["payload"], "task_selected_bool": true })
            })
    }

    Section_header_to_reset = (e) => {
        if (this.state.to === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i className="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ to: "" }, this.get_tasks);
                    this.typeahead.getInstance().clear()
                }}></i>
            )
        }

    }

    Section_header_to = (e) => {
        if (TP === "owner") {
            return (
                <AsyncTypeahead type="text" placeholder="Search" class="my-1" name="to"
                    isLoading={false}
                    id={`searchToOwner`}
                    labelKey={option => `${option.firstname} ${option.lastname} | ${option.username} | ${option.phone}`}
                    filterBy={['firstname', 'lastname', 'username']}
                    onSearch={this.handle_search}
                    {...this.state.asyncsearch}
                    maxResults={10}
                    ref={(typeahead) => this.typeahead = typeahead}
                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps} className="search_menu" id="search_menu">
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} className="search_item m-0">
                                    <div className="w-100 p-2 search_item" onClick={(e) => {
                                        this.setState({ to: result._id.$oid }, this.get_tasks)
                                    }}>{`${result.firstname} ${result.lastname} | ${result.username} | ${result.phone}`}</div>
                                </MenuItem>
                            ))}
                        </Menu>
                    )}

                />
            )
        }
        else {
            return (
                <b><label class="my-1" for="inlineFormCustomSelectPref">{FN}</label></b>
            )
        }
    }
    PrioritySelection = (e) => {
        return (
            <select id="sectionHeaderPrioritySelect" className="custom-select my-1 mr-sm-2 w-100" defaultValue={this.state.priority} onChange={(e) => {
                this.setState({ "priority": e.target.value, "page_number": 0 }, this.get_tasks)
            }}>
                <option value="All">All</option>
                <option value="Urgent">Urgent</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
            </select>
        )
    }

    Section_header_show = (e) => {
        return (
            <select id="sectionHeaderShowSelect" className="custom-select my-1 mr-sm-2 w-100" defaultValue={this.state.show} onChange={(e) => {
                this.setState({ "show": e.target.value, "page_number": 0 }, this.get_tasks)
            }}>
                <option value="r">Running</option>
                <option value="a">All</option>
                <option value="c">Closed</option>
                <option value="b">Before Sorted</option>
                <option value="ss">Scheduled Tasks</option>
            </select>
        )
    }

    handle_pagination_click = (e) => {
        var page_number = e.target.getAttribute("name")
        this.setState({ "page_number": parseInt(page_number) }, this.get_tasks)

    }

    Section_header_search_reset = (e) => {
        if (this.state.search_term === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i className="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ search_term: "" }, this.get_tasks);
                }}></i>
            )
        }

    }
    SelectionHeaderSearch = (e) => {
        return (
            <DebounceInput minLength={2} debounceTimeout={500} type="text" className="form-control my-1 w-100" id="selectionHeaderSearch" aria-describedby="search tasks" placeholder="Search Tasks" value={this.state.search_term} onChange={(e) => {
                this.setState({ search_term: e.target.value }, this.get_tasks)
            }} />
        )
    }
    SelectionHeader = (e) => {

        this.Pagination = (e) => {
            const total_pages = this.state.tasks[1]
            const page_count = parseInt(Math.ceil(total_pages / 10))
            var pages = []
            for (var i = 0; i < page_count; i++) {
                pages.push(
                    <a href="#" class="link" name={i} onClick={this.handle_pagination_click}>{parseInt(i + 1)}</a>
                )
                if (i != page_count - 1) {
                    pages.push(
                        <span>&nbsp;|&nbsp;</span>
                    )
                }
            }
            return (
                pages
            )
        }



        return (
            <form className="form-inline mt-3 ">
                <span className="d-inline-flex col-sm-12 col-xl-4 col-lg-4">
                    <label className="my-1 ml-2 mr-2" htmlFor="inlineFormCustomSelectPref">Priority:</label>
                    {<this.PrioritySelection />}
                </span>
                <span className="d-inline-flex col-sm-12 col-xl-4 col-lg-4">
                    <label className="my-1 ml-2 mr-2" htmlFor="inlineFormCustomSelectPref">Show:</label>
                    <this.Section_header_show />

                </span>
                <span className="d-inline-flex align-items-center col-sm-12 col-xl-4 col-lg-4">
                    <label className="my-1 ml-2 mr-2" htmlFor="inlineFormCustomSelectPref">To:</label>
                    {<this.Section_header_to />}
                    <this.Section_header_to_reset />
                </span>
                <span className="d-inline-flex align-items-center col-sm-12 col-xl-12 col-lg-12">
                    {/* <label className="my-1 ml-2 mr-2" for="inlineFormCustomSelectPref">Search:</label> */}
                    <this.SelectionHeaderSearch />
                    <this.Section_header_search_reset />
                </span>
            </form>
        );

    }

    SelectionFooter = (e) => {
        // this.Pagination = (e) => {
        //     const total_pages = this.state.tasks[1]
        //     const page_count = parseInt(Math.ceil(total_pages / 20))
        //     var pages = []
        //     for (var i = 0; i < page_count; i++) {
        //         pages.push(
        //             <a href="#" className="link" name={i} onClick={this.handle_pagination_click}>{parseInt(i + 1)}</a>
        //         )
        //         if (i != page_count - 1) {
        //             pages.push(
        //                 <span>&nbsp;|&nbsp;</span>
        //             )
        //         }
        //     }
        //     return (
        //         pages
        //     )
        // }
        const total_pages = this.state.tasks[1]

        return (

            <div className="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                <div className="d-inline-flex  justify-content-center align-items-center w-100">
                    <Pagination
                        innerClass="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.page_number + 1}
                        itemsCountPerPage={20}
                        totalItemsCount={total_pages}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            this.setState({ "page_number": parseInt(pageNumber) - 1 }, this.get_tasks)
                        }}
                    />
                </div>
            </div>

        )

        // return (
        //     <form className="form-inline mt-3">
        //         <label className="my-1 mr-2" for="inlineFormCustomSelectPref">Pages:</label>
        //         {<this.Pagination />}
        //         <label className="my-1 ml-2 mr-2" for="inlineFormCustomSelectPref">Showing Page:&nbsp;<b>{parseInt(this.state.page_number + 1)}</b></label>
        //     </form>
        // );
    }

    Status = (e) => {
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            return (
                <div className="alert alert-success mt-2" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div className="alert alert-danger mt-2" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }

    handle_search = (query) => {
        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'users', {
            params: {
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"] } })

            })
    }

    Dialog = (props) => {
        return (
            <div className="modal fade" id={props.label} tabIndex="-1" role="dialog" aria-labelledby={props.label} aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{props.h_text}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{props.msg}</p>
                            <p>Are you sure?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Exit</button>
                            <button type="button" className={`btn ` + props.c_text} id={props.b_id} onClick={props.handler} data-dismiss="modal">{props.b_label}</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    Task_to = (e) => {
        if (this.state.selected_task_update_bool && TP === "owner") {
            var ret = []
            ret.push(
                [
                    <label><b>Assigned To: </b></label>,
                    <p>{this.state.selected_task_update["_fi"]}</p>
                ]
            )

            ret.push(
                <AsyncTypeahead type="text" placeholder="Search" name="to"

                    labelKey={option => `${option.firstname} ${option.lastname} | ${option.username} | ${option.phone}`}
                    filterBy={['firstname', 'lastname', 'username']}
                    onSearch={this.handle_search}
                    {...this.state.asyncsearch}
                    maxResults="10"

                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps} className="search_menu" id="search_menu">
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} className="search_item m-0">
                                    <div className="w-100 p-2 search_item" onClick={(e) => {
                                        //   $("#search_menu").hide();
                                        var tmp_selected_task_update = this.state.selected_task_update
                                        tmp_selected_task_update["to"] = result._id.$oid
                                        tmp_selected_task_update["_fi"] = `${result.firstname} ${result.lastname} | ${result.username} | ${result.phone}`
                                        // console.log(tmp_selected_task_update)
                                        this.setState({ selected_task_update: tmp_selected_task_update })
                                    }}>{`${result.firstname} ${result.lastname} | ${result.username} | ${result.phone}`}</div>
                                </MenuItem>
                            ))}
                        </Menu>
                    )}

                />
            )
            return (
                <div class="form-group">
                    {ret}
                </div>
            )
        }
        else if (this.state.selected_task_close_bool) {
            return ("")
        }
        else if (this.state.task_selected_bool) {
            return [
                <label><b>Assigned To: </b></label>,
                <p>{this.state.selected_task["_fi"]}</p>
            ]
        }
    }

    handle_change = (e) => {
        var _name = e.target.getAttribute('name')
        var tmp_selected_task_update = this.state.selected_task_update
        tmp_selected_task_update[_name] = e.target.value
        this.setState({ selected_task_update: tmp_selected_task_update })
    }

    Task_title = (e) => {
        if (this.state.selected_task_update_bool) {
            return (
                <div class="form-group">
                    <label for="updateTitleInput"><b>Title</b></label>
                    <input type="text" class="form-control" id="updateTitleInput" name="title" value={this.state.selected_task_update.title} onChange={this.handle_change} />
                </div>
            )
        }
        else if (this.state.selected_task_close_bool) {
            return (
                <div class="form-group">
                    <label for="closeRemarks">Remarks</label>
                    <textarea class="form-control" id="closeRemarks" rows="3" name="remarks" value={this.state.remarks} onChange={(e) => {
                        this.setState({ remarks: e.target.value })
                    }}></textarea>
                </div>
            )
        }
        else if (this.state.task_selected_bool) {
            return (
                <p><h3>{this.state.selected_task["title"]}</h3></p>
            )
        }
    }

    handle_delete = (e) => {
        const that = this;

        var params = { _id: this.state.selected_task_id }
        axios.delete(URL + "t", {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] })
            })
    }

    handle_update = (e) => {

        $("#updatetasksbutton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Update")
        const that = this;


        var tmp_images = []

        if (this.state.edittasks_images_extra.length > 0) {
            for (var i in this.state.edittasks_images_extra) {
                tmp_images.push(
                    {
                        "image": this.state.edittasks_images_extra[i].split(",")[1],
                        "alt": ""
                    }
                )
            }
        }

        var params = { ut: "u" }

        var beforedate_ = ""
        if (this.state.selected_task_update.beforedate.$date !== "") {
            beforedate_ = new Date(this.state.selected_task_update.beforedate.$date)
        }
        var scheduledate_ = ""
        if (this.state.selected_task_update.scheduledate.$date !== "") {
            scheduledate_ = new Date(this.state.selected_task_update.scheduledate.$date)
        }

        axios.patch(URL + "t", {
            _id: this.state.selected_task_id,
            title: this.state.selected_task_update.title,
            description: this.state.selected_task_update.description,
            info: this.state.selected_task_update.info,
            priority: this.state.selected_task_update.priority,
            to: this.state.selected_task_update.to,
            beforedate: beforedate_,
            scheduledate: scheduledate_,
            images: this.state.edittasks_images,
            extra_images: tmp_images

        }, {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] })
                $("#updatetasksbutton").html("Update")
                // alert({ response: response["data"] })
            })
    }
    handle_info_update = (e) => {
        $("#updateinfobutton").html("<i id=\"spin_submit_button\" class=\"fa fa-circle-o-notch fa-spin\"></i> Update Info")
        const that = this;

        var params = { ut: "i" }

        axios.patch(URL + "t", {
            _id: this.state.selected_task_id,
            info: this.state.selected_task_info_update

        }, {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] })
                $("#updateinfobutton").html("Update Info")
                // alert({ response: response["data"] })
            })
    }
    handle_close = (e) => {
        const that = this;

        var params = { ut: "c" }

        axios.patch(URL + "t", {
            _id: this.state.selected_task_id,
            remarks: this.state.remarks
        }, {
            headers: { Authorization: ACCT },
            params: params
        })
            .then(function (response) {
                that.setState({ response: response["data"] })
            })
    }

    reset_response = (e) => {
        this.setState({ response: {} })
    }

    Task_description = (e) => {
        if (this.state.selected_task_update_bool) {
            if (TP === "owner") {

                return (
                    <div class="form-group">
                        <label for="updateDescriptionInput"><b>Description</b></label>
                        <textarea class="form-control" id="updateDescriptionInput" rows="3" style={{ whiteSpace: "pre-wrap" }} name="description" value={this.state.selected_task_update.description} onChange={this.handle_change}></textarea>
                    </div>
                )
            }
            else {
                return (
                    ""
                )
            }
        }
        else if (this.state.selected_task_close_bool) {
            return ("")
        }
        else if (this.state.task_selected_bool) {
            return [
                <label><b>Description</b></label>,
                <p style={{ whiteSpace: "pre-wrap" }}>{this.state.selected_task["description"]}</p>
            ]

        }
    }

    Task_info = (e) => {
        if (this.state.selected_task_update_bool) {
            return (
                ""
            )
        }
        else if (this.state.selected_task_close_bool) {
            return ("")
        }
        else if (this.state.task_selected_bool) {
            return [
                <label><b>Info</b></label>,
                <p style={{ whiteSpace: "pre-wrap" }}>{this.state.selected_task["info"]}</p>
            ]

        }
    }

    Before_date_reset = (e) => {
        if (this.state.selected_task_update.beforedate.$date === "" || this.state.selected_task_update.beforedate.$date === null) {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    var tmp_selected_task_update = this.state.selected_task_update
                    tmp_selected_task_update["beforedate"]["$date"] = ""
                    this.setState({ selected_task_update: tmp_selected_task_update })
                }}></i>
            )
        }

    }

    Schedule_date_reset = (e) => {
        if (this.state.selected_task_update.scheduledate.$date === "" || this.state.selected_task_update.scheduledate.$date === null) {
            return (
                ""
            )
        }
        else {
            return (
                <i class="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    var tmp_selected_task_update = this.state.selected_task_update
                    tmp_selected_task_update["scheduledate"]["$date"] = ""
                    this.setState({ selected_task_update: tmp_selected_task_update })
                }}></i>
            )
        }

    }

    Task_image = (e) => {
        if (this.state.selected_task_update_bool) {
            return (
                <this.ImageViewEdit />
            )
        }
        else if (this.state.selected_task_close_bool) {
            return ("")
        }
        else if (this.state.task_selected_bool) {
            return [
                <this.ImageView />
            ]

        }
    }
    SelectedTask = (e) => {

        const task = this.state.selected_task



        this.priority_button_click_handle = (e) => {
            var id = e.target.id;
            var _name = e.target.name;
            var tmp_state = this.state;
            tmp_state.selected_task_update.priority = _name
            this.setState(tmp_state)
        }

        this.Task_back_arrow = (e) => {
            return [
                <div class="back_button" onClick={(e) => {
                    if (this.state.selected_task_update_bool) {
                        this.reset_response();
                        this.get_single_task();
                        this.setState({ selected_task_update_bool: false })
                    }
                    else if (this.state.selected_task_close_bool) {
                        this.reset_response();
                        this.setState({ task_selected_bool: true, selected_task_update_bool: false, selected_task_close_bool: false })
                    }
                    else if (this.state.task_selected_bool) {
                        this.reset_response();
                        this.setState({ task_selected_bool: false, selected_task_update_bool: false }, () => { this.get_tasks(); this.initTimer(); })
                    }
                }}>
                    <h5>
                        <i className="fas fa-arrow-left mr-2 mb-3"></i>
                        <span>Back</span>
                    </h5>
                </div>
            ]
        }

        this.Task_created = (e) => {
            var createdby_ = ""
            if (task["_createdby"] === "") {
                createdby_ = <i style={{ color: 'rgb(253, 99, 43)' }} className="fas fa-star"></i>
            }
            else {
                createdby_ = task["_createdby"]
            }
            var taskClosedText_ = ""
            if (task["_completed"]) {
                if (task["_completedt"] != undefined) {
                    taskClosedText_ = "Closed On: " + moment(task["_completedt"].$date).format('Do MMMM YYYY, h:mm:ss A')
                } 
            }

            if (this.state.selected_task_update_bool) {
                return ("")
            }
            else if (this.state.task_selected_bool) {
                return [
                    <label><b>Created On And By</b></label>,
                    <p>
                        {moment(task["_created"].$date).format('Do MMMM YYYY, h:mm:ss A')}
                        <br />
                        {createdby_}
                        <br />
                        {taskClosedText_}
                    </p>
                ]
            }
            return ("")
        }

        this.Task_priority = (e) => {
            if (this.state.selected_task_update_bool) {

                var urgent_button = <button type="button" id="update_priority_buttons_urgent" name="Urgent" className="btn btn-secondary priority-buttons border-right-0" onClick={this.priority_button_click_handle}>Urgent</button>
                var high_button = <button type="button" id="update_priority_buttons_high" name="High" className="btn btn-secondary priority-buttons border-left-0 border-right-0" onClick={this.priority_button_click_handle}>High</button>
                var medium_button = <button type="button" id="update_priority_buttons_medium" name="Medium" className="btn btn-secondary priority-buttons border-left-0 border-right-0" onClick={this.priority_button_click_handle}>Medium</button>
                var low_button = <button type="button" id="update_priority_buttons_low" name="Low" className="btn btn-secondary priority-buttons border-left-0" onClick={this.priority_button_click_handle}>Low</button>

                if (this.state.selected_task_update.priority === "Urgent") {
                    urgent_button = <button type="button" id="update_priority_buttons_urgent" name="Urgent" className="btn btn-secondary priority-buttons border-right-0" onClick={this.priority_button_click_handle} style={{ backgroundColor: "crimson", color: "white" }}>Urgent</button>
                }
                if (this.state.selected_task_update.priority === "High") {
                    high_button = <button type="button" id="update_priority_buttons_high" name="High" className="btn btn-secondary priority-buttons border-left-0 border-right-0" onClick={this.priority_button_click_handle} style={{ backgroundColor: "darkred", color: "white" }}>High</button>
                }
                if (this.state.selected_task_update.priority === "Medium") {
                    medium_button = <button type="button" id="update_priority_buttons_medium" name="Medium" className="btn btn-secondary priority-buttons border-left-0 border-right-0" onClick={this.priority_button_click_handle} style={{ backgroundColor: "orangered", color: "white" }}>Medium</button>
                }
                if (this.state.selected_task_update.priority === "Low") {
                    low_button = <button type="button" id="update_priority_buttons_low" name="Low" className="btn btn-secondary priority-buttons border-left-0" onClick={this.priority_button_click_handle} style={{ backgroundColor: "green", color: "white" }}>Low</button>
                }
                return (
                    <div className="form-group">
                        <label for="updatePriority"><b>Priority</b></label>
                        <br />
                        <div className="btn-group" id="updatePriority" role="group">
                            {urgent_button}
                            {high_button}
                            {medium_button}
                            {low_button}
                        </div>
                    </div>
                )
            }
            else if (this.state.selected_task_close_bool) {
                return ("")
            }
            else if (this.state.task_selected_bool) {
                var priority_badge = []
                if (task["priority"] === "Urgent") {
                    priority_badge.push(
                        <span className="badge badge-pill badgeW2 urgent_p">{task["priority"]}</span>
                    )
                }
                if (task["priority"] === "High") {
                    priority_badge.push(
                        <span className="badge badge-pill badgeW2 high_p">{task["priority"]}</span>
                    )
                }
                if (task["priority"] === "Medium") {
                    priority_badge.push(
                        <span className="badge badge-pill badgeW2 medium_p">{task["priority"]}</span>
                    )
                }
                if (task["priority"] === "Low") {
                    priority_badge.push(
                        <span className="badge badge-pill badgeW2 low_p">{task["priority"]}</span>
                    )
                }

                var completed_badge = []
                if (task["_completed"] === true) {
                    completed_badge.push(
                        <span className="badge badge-pill badgeW2 badge-success mr-2">Closed</span>
                    )
                }

                var final_badge = <div>{completed_badge}{priority_badge}</div>

                return (
                    <h5>{final_badge}</h5>
                )
            }
        }

        this.Task_before_date = (e) => {
            if (this.state.selected_task_update_bool) {
                var selected_date = ""
                if (this.state.selected_task_update.beforedate != null) {
                    selected_date = new Date(this.state.selected_task_update.beforedate.$date)
                }
                else {
                    var tmp_selected_task_update = this.state.selected_task_update
                    tmp_selected_task_update["beforedate"] = { "$date": "" }
                    this.setState({ selected_task_update: tmp_selected_task_update })
                }
                return (
                    <div class="form-group">
                        <label for="beforeDate"><b>Before</b></label>
                        <div>
                            <DatePicker
                                id="beforeDate"
                                selected={this.state.selected_task_update.beforedate.$date}
                                onChange={(date) => {
                                    var tmp_selected_task_update = this.state.selected_task_update
                                    tmp_selected_task_update["beforedate"]["$date"] = date
                                    this.setState({ selected_task_update: tmp_selected_task_update })
                                }}
                                dateFormat="dd/MM/yyyy p"
                                className="form-control w-100"
                                showTimeSelect

                            />
                            {<this.Before_date_reset />}
                        </div>
                    </div>
                )
            }
            else if (this.state.task_selected_bool) {
                if (task["beforedate"] != null) {
                    return [
                        <label for="beforeDate"><b>Before</b></label>,
                        <p>{moment(task["beforedate"].$date).format('Do MMMM YYYY, h:mm:ss A')}</p>
                    ]
                }
                return ("")
            }
            return ("")
        }


        this.Task_schedule = (e) => {
            if (this.state.selected_task_update_bool) {
                var selected_date = ""
                if (this.state.selected_task_update.scheduledate != null) {
                    selected_date = new Date(this.state.selected_task_update.scheduledate.$date)
                }
                else {
                    var tmp_selected_task_update = this.state.selected_task_update
                    tmp_selected_task_update["scheduledate"] = { "$date": "" }
                    this.setState({ selected_task_update: tmp_selected_task_update })
                }
                return (
                    <div class="form-group">
                        <label for="scheduleDate"><b>Schedule</b></label>
                        <div>
                            <DatePicker
                                id="scheduleDate"
                                selected={this.state.selected_task_update.scheduledate.$date}
                                onChange={(date) => {
                                    var tmp_selected_task_update = this.state.selected_task_update
                                    tmp_selected_task_update["scheduledate"]["$date"] = date
                                    this.setState({ selected_task_update: tmp_selected_task_update })
                                }}
                                dateFormat="dd/MM/yyyy p"
                                className="form-control w-100"
                                showTimeSelect

                            />
                            {<this.Schedule_date_reset />}
                        </div>
                    </div>
                )
            }
            else if (this.state.task_selected_bool) {
                if (task["scheduledate"] != null) {
                    return [
                        <label htmlFor="scheduleDate"><b>Schedule</b></label>,
                        <p>{moment(task["scheduledate"].$date).format('Do MMMM YYYY, h:mm:ss A')}</p>
                    ]
                }
                return ("")
            }
            return ("")
        }

        this.Task_buttons = (e) => {

            if (this.state.selected_task_update_bool) {
                return (
                    <div class="form-group">
                        {/* <a href="#" class="btn btn-primary m-2 ml-0" onClick={() => { this.reset_response(); this.setState({ selected_task_update_bool: false }) }}>Back</a> */}
                        <a href="#" class="btn btn-info m-2" data-toggle="modal" id="updatetasksbutton" data-target="#updateTaskDialog">Update</a>
                    </div>
                )
            }
            else if (this.state.selected_task_close_bool) {
                return (
                    <div class="form-group">
                        {/* <a href="#" class="btn btn-primary m-2 ml-0" onClick={() => { this.reset_response(); this.setState({ task_selected_bool: true, selected_task_update_bool: false, selected_task_close_bool: false }) }}>Back</a> */}
                        <a href="#" class="btn btn-success m-2" data-toggle="modal" data-target="#closeTaskDialog">Close</a>
                    </div>
                );
            }
            else if (this.state.task_selected_bool) {

                var delete_button = ""
                if (TP === "owner") {
                    delete_button = <a href="#" class="btn btn-danger m-2" data-toggle="modal" data-target="#deleteTaskDialog">Delete</a>
                }
                else {
                    delete_button = ""
                }

                return (
                    <div class="form-group">
                        {/* <a href="#" class="btn btn-primary m-2 ml-0" onClick={() => { this.reset_response(); this.setState({ task_selected_bool: false, selected_task_update_bool: false }, this.get_tasks) }}>Back</a> */}
                        <a href="#" class="btn btn-info m-2" onClick={() => {
                            var images_ = this.state.selected_task.images;
                            if (images_ == undefined){
                                images_ = []
                            }
                            this.reset_response(); this.setState({ edittask_images_extra: [], edittasks_images: images_ },
                                this.setState({ selected_task_update: {}, selected_task_update: { ...this.state.selected_task }, selected_task_update_bool: true, selected_task_close_bool: false, edittasks_images_extra: [] }))
                        }}>Update</a>

                        <a href="#" class="btn btn-info m-2" data-toggle="modal" data-target="#updateInfoDialog" onClick={() => {
                            this.reset_response();
                            var info = this.state.selected_task.info;
                            if (info === undefined) {
                                info = ""
                            }
                            this.setState({ selected_task_info_update: info })
                        }}>Update Info</a>

                        <a href="#" class="btn btn-success m-2" onClick={() => { this.reset_response(); this.setState({ selected_task_close_bool: true, selected_task_update_bool: false, selected_task_close: {} }) }}>Close</a>
                        {delete_button}
                    </div>
                )
            }
        }

        this.Task_remarks = (e) => {
            if (this.state.task_selected_bool) {
                if (task["_completed"] === true) {
                    return (
                        <div class="form-group">
                            <label><b>Remarks</b></label>
                            <p style={{ whiteSpace: "pre-wrap" }}>{task["_remarks"]}</p>
                        </div>
                    )
                }
                else {
                    return ("")
                }
            } else {
                return ("")
            }
        }



        return (
            <div class="container mt-3">
                <div>
                    <this.Task_back_arrow />
                </div>
                <div>
                    <this.Task_title />
                </div>
                <div>
                    <this.Task_priority />
                </div>

                <div>
                    <this.Task_description />
                </div>
                <div>
                    <this.Task_info />
                </div>
                <div>
                    <this.Task_remarks />
                </div>
                <div>
                    <this.Task_created />
                </div>
                <div>
                    <this.Task_to />
                </div>
                <div>
                    <this.Task_before_date />
                </div>
                <div>
                    <this.Task_schedule />
                </div>
                <div>
                    <this.Task_image />
                </div>
                <div>
                    <this.Task_buttons />
                </div>
                <div>
                    <this.Status />
                </div>
                <this.Dialog name="delete" msg="Delete Task." h_text="Delete Task" c_text="btn-danger" b_label="Delete" handler={this.handle_delete} label="deleteTaskDialog" />
                <this.Dialog name="close" msg="Close Task." h_text="Close Task" c_text="btn-success" b_label="Close" handler={this.handle_close} label="closeTaskDialog" />
                <this.Dialog name="update" msg="Update Task." h_text="Update Task" c_text="btn-info" b_label="Update" handler={this.handle_update} label="updateTaskDialog" />
                <div class="modal fade" id="updateInfoDialog" tabIndex="-1" role="dialog" aria-labelledby="updateInfoDialog" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Update Info</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label for="updateInfoInput"><b>Info</b></label>
                                    <textarea class="form-control" id="updateInfoInput" rows="3" style={{ whiteSpace: "pre-wrap" }} name="info" value={this.state.selected_task_info_update} onChange={(e) => {
                                        this.setState({ selected_task_info_update: e.target.value })
                                    }}></textarea>
                                </div>
                                <this.Status />
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => {
                                    this.reset_response();
                                    this.get_single_task();
                                }}>Close</button>
                                <button type="button" class="btn btn-info" id="updateinfobutton" onClick={this.handle_info_update}>Update Info</button>
                            </div>
                        </div>
                    </div>
                </div>
                <this.FullScreenImageView />
            </div>
        )
    }

    handle_task_select = (e) => {
        var lid = e.target.getAttribute('lid');
        // console.log(lid)
        this.setState({ selected_task_id: lid }, this.get_single_task)
    }
    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialogimage').style.transform = angle;
        })
    }

    FullScreenImageView = (e) => {
        return (
            <div id="fullscreenimageviewdialog" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog") {
                        $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog').attr('hidden', 'hidden') }}>
                    <h3>
                        <i class="fas fa-times"></i> Close
                    </h3>
                </div>
                <div class="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialogimage" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }
    ImageView = (e) => {

        if ("images" in this.state.selected_task && this.state.selected_task.images.length > 0) {
            var ret = []

            // add image tags
            for (var i in this.state.selected_task.images) {
                const that = this;
                ret.push(
                    <div key={this.state.selected_task.images[i]["image"]} class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img lid={this.state.selected_task.images[i]["image"]} class="card-img-top" id={`img-` + this.state.selected_task.images[i]["image"]} onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog').removeAttr('hidden')
                            $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                        }} />
                    </div>
                )
            }

            // aquire images
            for (var i in this.state.selected_task.images) {
                const that = this;

                const _id = this.state.selected_task.images[i]["image"]
                var params = { "_id": _id }
                axios.get(URL + "img", {
                    responseType: "blob",
                    headers: { Authorization: ACCT },
                    params: params
                })
                    .then(function (response) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {

                            var imageDataUrl = reader.result;
                            var img_id = "img-" + _id
                            document.getElementById(img_id).setAttribute("src", imageDataUrl);

                        }

                    })
            }


            return (
                <div class="card" >
                    <div class="card-body">
                        <h5 class="card-title">Images</h5>
                        <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
                        <div class="d-flex flex-wrap align-items-center">
                            {ret}
                        </div>
                    </div>
                </div>
            )
        }
        return ("")
    }

    ImageViewEdit = (e) => {
        var ret = []

        if (this.state.edittasks_images_extra != undefined && this.state.edittasks_images != undefined) {
            if ((this.state.edittasks_images.length + this.state.edittasks_images_extra.length) > 0) {

                // add image tags
                for (var i in this.state.edittasks_images) {
                    const that = this;
                    ret.push(
                        <div key={this.state.edittasks_images[i]["image"]} class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                            <img lid={this.state.edittasks_images[i]["image"]} class="card-img-top" id={`img-` + this.state.edittasks_images[i]["image"]} onClick={(e) => {
                                this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                                document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                                $('#fullscreenimageviewdialog').removeAttr('hidden')
                                $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                            }} />
                            <i class="fas fa-times" iid={i} onClick={(e) => {
                                var iid_ = e.target.getAttribute("iid")
                                var tmp_edittasks_images = this.state.edittasks_images;
                                tmp_edittasks_images.splice(parseInt(iid_), 1);
                                this.setState({ edittasks_images: tmp_edittasks_images });
                            }}> Delete</i>
                        </div>
                    )
                }

                // aquire images
                for (var i in this.state.edittasks_images) {
                    const that = this;

                    const _id = this.state.edittasks_images[i]["image"]
                    var params = { "_id": _id }
                    axios.get(URL + "img", {
                        responseType: "blob",
                        headers: { Authorization: ACCT },
                        params: params
                    })
                        .then(function (response) {
                            var reader = new window.FileReader();
                            reader.readAsDataURL(response.data);
                            reader.onload = function () {

                                var imageDataUrl = reader.result;
                                var img_id = "img-" + _id
                                document.getElementById(img_id).setAttribute("src", imageDataUrl);

                            }

                        })
                }


                for (var i in this.state.edittasks_images_extra) {
                    ret.push(
                        <div key={i} class="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                            <img iid={i} src={this.state.edittasks_images_extra[i]} class="card-img-top" onClick={(e) => {
                                this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                                document.getElementById("fullscreenimageviewdialogimage").setAttribute("src", e.target.src)
                                $('#fullscreenimageviewdialog').removeAttr('hidden')
                                $('#fullscreenimageviewdialog').css("background", "rgba(0,0,0,0.5)")
                            }} />
                            <i class="fas fa-times" iid={i} onClick={(e) => {
                                var iid = e.target.getAttribute('iid');
                                var tmp_images = this.state.edittasks_images_extra;
                                tmp_images.splice(parseInt(iid), 1)
                                this.setState({ images: tmp_images })
                            }}> Delete</i>
                        </div>
                    )
                }

            }
        }

        var help_text = ""
        if (this.state.edittasks_images_extra != undefined && this.state.edittasks_images != undefined) {
            if (this.state.edittasks_images.length + this.state.edittasks_images_extra.length > 0) {

                help_text = <small id="imageupload_help" class="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            }
        }

        return (
            <div class="card" >
                <div class="card-body">
                    <h5 class="card-title">Edit Images</h5>
                    {help_text}
                    <div class="d-flex flex-wrap align-items-center">
                        {ret}
                    </div>
                    <button type="button" class="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile2').click();
                    }}>Add Image(s)</button>
                    {/* {clear_all_button} */}
                    <input type="file" class="form-control-file" id="exampleFormControlFile2" onChange={(input) => {
                        const that = this;
                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.edittasks_images_extra;

                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ edittasks_images_extra: tmp_images })
                                document.getElementById('exampleFormControlFile2').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )


    }
    Tasks = (e) => {
        var tasks = []

        for (var t in this.state.tasks[0]) {
            var priority_badge = []
            if (this.state.tasks[0][t]["priority"] === "Urgent") {
                priority_badge.push(
                    <span key={`urgentPriorityKey`} lid={this.state.tasks[0][t]["_id"]["$oid"]} className="badge badge-pill badgeW urgent_p m-1">{this.state.tasks[0][t]["priority"]}</span>
                )
            }
            if (this.state.tasks[0][t]["priority"] === "High") {
                priority_badge.push(
                    <span key={`highPriorityKey`} lid={this.state.tasks[0][t]["_id"]["$oid"]} className="badge badge-pill badgeW high_p m-1">{this.state.tasks[0][t]["priority"]}</span>
                )
            }
            if (this.state.tasks[0][t]["priority"] === "Medium") {
                priority_badge.push(
                    <span key={`mediumPriorityKey`} lid={this.state.tasks[0][t]["_id"]["$oid"]} className="badge badge-pill badgeW medium_p m-1">{this.state.tasks[0][t]["priority"]}</span>
                )
            }
            if (this.state.tasks[0][t]["priority"] === "Low") {
                priority_badge.push(
                    <span key={`lowPriorityKey`} lid={this.state.tasks[0][t]["_id"]["$oid"]} className="badge badge-pill badgeW low_p m-1">{this.state.tasks[0][t]["priority"]}</span>
                )
            }

            var completed_badge = []
            if (this.state.tasks[0][t]["_completed"] === true) {
                completed_badge.push(
                    <span lid={this.state.tasks[0][t]["_id"]["$oid"]} className="badge badge-pill badgeW badge-success m-1">Closed</span>
                )
            }

            var is_task_created_by_owner_badge = ""
            if ("_createdbyowner" in this.state.tasks[0][t] && this.state.tasks[0][t]._createdbyowner === true) {
                is_task_created_by_owner_badge = <i lid={this.state.tasks[0][t]["_id"]["$oid"]} style={{ color: 'rgb(253, 99, 43)' }} className="fas fa-star"></i>
                if (this.state.tasks[0][t]["beforedate"] != null && this.state.tasks[0][t]["_completed"] != true) {
                    if (new Date() > new Date(this.state.tasks[0][t]["beforedate"].$date)) {
                        is_task_created_by_owner_badge = <i lid={this.state.tasks[0][t]["_id"]["$oid"]} style={{ color: 'white' }} className="fas fa-star"></i>
                    }
                }
                // is_task_created_by_owner_badge = <span lid={this.state.tasks[0][t]["_id"]["$oid"]} className="badge badge-pill badge-info m-1">Owner</span>
            }
            var attachment_badge = []
            
            if ("images" in this.state.tasks[0][t]) {
                var count = Object.keys(this.state.tasks[0][t]["images"]).length;
                if (count > 0) {
                    attachment_badge = <i lid={this.state.tasks[0][t]["_id"]["$oid"]} style={{ color: 'rgb(253, 99, 43)' }} className="fas fa-paperclip"></i>
                    if (this.state.tasks[0][t]["beforedate"] != null && this.state.tasks[0][t]["_completed"] != true) {
                        if (new Date() > new Date(this.state.tasks[0][t]["beforedate"].$date)) {
                            attachment_badge = <i lid={this.state.tasks[0][t]["_id"]["$oid"]} style={{ color: 'white' }} className="fas fa-paperclip"></i>
                        }
                    }
                }
            }
            

        var final_badge = <div className="d-flex flex-row align-items-center" lid={this.state.tasks[0][t]["_id"]["$oid"]}>{is_task_created_by_owner_badge}{attachment_badge}{completed_badge}{priority_badge}</div>


            var task_ = <a key={this.state.tasks[0][t]["_id"]["$oid"]} href="#" className="list-group-item d-flex justify-content-between align-items-center list-group-item-action search_item" lid={this.state.tasks[0][t]["_id"]["$oid"]} onClick={this.handle_task_select}><span className="mr-2 d-flex justify-content-between align-items-center" lid={this.state.tasks[0][t]["_id"]["$oid"]}><span className="mr-2" lid={this.state.tasks[0][t]["_id"]["$oid"]}>{moment(this.state.tasks[0][t]["_created"].$date).format('D MMM')}</span> <span lid={this.state.tasks[0][t]["_id"]["$oid"]}><b lid={this.state.tasks[0][t]["_id"]["$oid"]}>{this.state.tasks[0][t]["title"]}</b></span></span> {final_badge}</a>

            if (this.state.tasks[0][t]["beforedate"] != null && this.state.tasks[0][t]["_completed"] != true) {
                if (new Date() > new Date(this.state.tasks[0][t]["beforedate"].$date)) {
                    task_ = <a href="#" className="list-group-item d-flex justify-content-between align-items-center list-group-item-action search_item expired_task" lid={this.state.tasks[0][t]["_id"]["$oid"]} onClick={this.handle_task_select}><span className="mr-2 d-flex justify-content-between align-items-center" lid={this.state.tasks[0][t]["_id"]["$oid"]}><span className="mr-2" lid={this.state.tasks[0][t]["_id"]["$oid"]}>{moment(this.state.tasks[0][t]["_created"].$date).format('D MMM')}</span> <span lid={this.state.tasks[0][t]["_id"]["$oid"]}><b lid={this.state.tasks[0][t]["_id"]["$oid"]}>{this.state.tasks[0][t]["title"]}</b></span></span> {final_badge}</a>
                }
            }

            tasks.push(
                task_
            )
        }
        return (
            <div>
                <this.SelectionHeader />
                <this.SelectionFooter />
                <div className="list-group ">
                    {tasks}
                </div>
                <this.SelectionFooter />
            </div>
        )
    }

    render() {
        if (this.state.task_selected_bool) {
            this.deinitTimer();
            return (
                <this.SelectedTask />
            );
        } else {
            return (
                <this.Tasks />
            )

        }
    }
}

export default Tasks;
