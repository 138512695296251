import React, { useEffect } from 'react';
import Tasks from './Tasks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead'; // ES2015
import Cookies from 'js-cookie';
import URL from './URL';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastsDispatcher } from './Toasts';
import { fullDialogDispatcher } from './FullDialog';
import moment from 'moment-timezone';

const axios = require('axios').default;
const loading_icon = <FontAwesomeIcon icon={faCircleNotch} size="lg" />
const camelCase = require('camelcase');

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var ACCT = Cookies.get('ACCT');
var FN = Cookies.get('FN');
var U = Cookies.get('U');

class Create extends React.Component {
    constructor(props) {
        super(props);
        this.priority_button_click_handle = this.priority_button_click_handle.bind(this);
        this.handle_tasks_post = this.handle_tasks_post.bind(this);
        this.Status = this.Status.bind(this);
        this.state = {
            title: "", description: "", priority: "", to_id: "", to_search: "", schedule_date: "", before_date: "", response: {}, asyncsearch: { isLoading: false, options: [] }, images: [], isLoading: false
        }
        this.handle_search = this.handle_search.bind(this);
    }

    Status = (e) => {
        useEffect(() => {
            // Update the document title using the browser API
            if ("_status" in this.state.response) {
                document.getElementById('createStatusDiv').focus()
            }
        });
        if ("_status" in this.state.response && this.state.response["_status"] === "success") {
            return (
                <div className="alert alert-success mt-2" id="createStatusDiv" role="alert">
                    {this.state.response["payload"]}
                </div>
            );
        } else if ("_status" in this.state.response && this.state.response["_status"] === "fail") {

            var ret = []
            for (var i in this.state.response["payload"][0]) {
                // console.log(this.state.response.payload[0][i])
                ret.push(
                    <span>{i}: {this.state.response.payload[0][i]}<br /></span>
                );
            }
            return (
                <div className="alert alert-danger mt-2" id="createStatusDiv" role="alert">
                    {ret}
                </div>
            );
        }
        return ("");
    }
    priority_button_click_handle = (e) => {
        var id = e.target.id;
        var _name = e.target.name;
        this.setState({ priority: _name })
        if (id === "priority_buttons_urgent") {
            $("#priority_buttons_urgent").css("background-color", "crimson");
            $("#priority_buttons_urgent").css("color", "white");
            $("#priority_buttons_high").css("background-color", "white");
            $("#priority_buttons_high").css("color", "#545b62");
            $("#priority_buttons_medium").css("background-color", "white");
            $("#priority_buttons_medium").css("color", "#545b62");
            $("#priority_buttons_low").css("background-color", "white");
            $("#priority_buttons_low").css("color", "#545b62");
        }
        if (id === "priority_buttons_high") {
            $("#priority_buttons_urgent").css("background-color", "white");
            $("#priority_buttons_urgent").css("color", "#545b62");
            $("#priority_buttons_high").css("background-color", "darkred");
            $("#priority_buttons_high").css("color", "white");
            $("#priority_buttons_medium").css("background-color", "white");
            $("#priority_buttons_medium").css("color", "#545b62");
            $("#priority_buttons_low").css("background-color", "white");
            $("#priority_buttons_low").css("color", "#545b62");
        }
        if (id === "priority_buttons_medium") {
            $("#priority_buttons_urgent").css("background-color", "white");
            $("#priority_buttons_urgent").css("color", "#545b62");
            $("#priority_buttons_high").css("background-color", "white");
            $("#priority_buttons_high").css("color", "#545b62");
            $("#priority_buttons_medium").css("background-color", "orangered");
            $("#priority_buttons_medium").css("color", "white");
            $("#priority_buttons_low").css("background-color", "white");
            $("#priority_buttons_low").css("color", "#545b62");
        }
        if (id === "priority_buttons_low") {
            $("#priority_buttons_urgent").css("background-color", "white");
            $("#priority_buttons_urgent").css("color", "#545b62");
            $("#priority_buttons_high").css("background-color", "white");
            $("#priority_buttons_high").css("color", "#545b62");
            $("#priority_buttons_medium").css("background-color", "white");
            $("#priority_buttons_medium").css("color", "#545b62");
            $("#priority_buttons_low").css("background-color", "green");
            $("#priority_buttons_low").css("color", "white");
        }
    }

    handle_change = (e) => {
        const _name = e.target.name;
        const _value = e.target.value;
        var tmp_state = this.state;
        tmp_state[_name] = _value;
        this.setState(tmp_state)
    }

    handle_tasks_post = (e) => {
        const that = this;

        // $("#postTaskButton").html("<i id=\"spin_submit_button\" className=\"fa fa-circle-o-notch fa-spin\"></i> Create")
        this.setState({ isPostLoading: true })

        var tmp_images = []

        if (this.state.images.length > 0) {
            for (var i in this.state.images) {
                tmp_images.push(
                    {
                        "image": this.state.images[i].split(",")[1],
                        "alt": ""
                    }
                )
            }
        }

        axios.post(URL + "t", {
            title: this.state.title,
            description: this.state.description,
            priority: this.state.priority,
            to: this.state.to_id,
            scheduledate: this.state.schedule_date,
            beforedate: this.state.before_date,
            images: tmp_images
        }, {
            headers: {
                Authorization: ACCT
            }
        })
            .then(function (response) {

                that.setState({ response: response["data"], isPostLoading: false })
                // console.log(response["data"])
            })
            .catch(function (error) {
                that.setState({ response: { "_status": "fail", "payload": "" }, isPostLoading: false })

            })
    }

    handle_search = (query) => {
        this.setState({ isLoading: true })
        const that = this;
        axios.get(URL + 'users', {
            params: {
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"] } })
            })
    }

    Schedule_date_reset = (e) => {
        if (this.state.schedule_date === "") {
            return (
                ""
            )
        }
        else {

            return (
                <i className="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ schedule_date: "" })
                }}></i>
            )
        }

    }
    Before_date_reset = (e) => {
        if (this.state.before_date === "") {
            return (
                ""
            )
        }
        else {
            return (
                <i className="far fa-times-circle ml-2" style={{ cursor: "pointer" }} onClick={() => {
                    this.setState({ before_date: "" })
                }}></i>
            )
        }

    }

    To = (e) => {
        if (TP === "owner") {
            return (
                <AsyncTypeahead type="text" placeholder="Search" name="to"
                    id="searchInput"
                    labelKey={option => `${option.firstname} ${option.lastname} | ${option.username} | ${option.phone}`}
                    filterBy={['firstname', 'lastname', 'username']}
                    onSearch={this.handle_search}
                    {...this.state.asyncsearch}
                    maxResults="10"

                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps} className="search_menu" id="search_menu">
                            {results.map((result, index) => (
                                <MenuItem option={result} position={index} className="search_item m-0">
                                    <div className="w-100 p-2 search_item" onClick={(e) => {
                                        //   $("#search_menu").hide();
                                        this.setState({ to_id: result._id.$oid })
                                    }}>{`${result.firstname} ${result.lastname} | ${result.username} | ${result.phone}`}</div>
                                </MenuItem>
                            ))}
                        </Menu>
                    )}

                />
            )
        }
        else {
            return (
                <b>:&nbsp;{FN}</b>
            )
        }
    }

    FullScreenImageView = (e) => {
        return (
            <div id="fullscreenimageviewdialog1" class="" style={{ width: "100%", position: "fixed", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(0, 0, 0, 0.0)", zIndex: "800", transition: "background 2s linear", display: "block", transform: "none", overflowX: "hidden", overflowY: "auto" }} tabindex="-1" hidden onClick={
                (e) => {
                    if (e.target.id == "fullscreenimageviewdialog1") {
                        $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                    }
                }
            }>
                <div style={{ position: "absolute", "right": "20px", "top": "20px", cursor: "pointer", color: "white" }} onClick={() => { $('#fullscreenimageviewdialog1').attr('hidden', 'hidden') }}>
                    <h3>
                        <i className="fas fa-times"></i> Close
                    </h3>
                </div>
                <div className="container d-flex justify-content-center align-items-center h-100" onClick={() => {
                    // $('#fullscreenimageviewdialog1').attr('hidden', 'hidden')
                }}>
                    <img src="" id="fullscreenimageviewdialog1image" style={{ height: "auto", width: "auto", maxHeight: "90%", maxWidth: "90%" }} onClick={() => {
                        // $('#fullscreenimageviewdialog').attr('hidden', 'hidden')
                        this.rotate_image()
                    }} />
                </div>
            </div>
        )
    }

    rotate_image = (e) => {
        this.setState({ fullscreenimageviewdialogimagerotationangle: this.state.fullscreenimageviewdialogimagerotationangle + 90 }, () => {
            var angle = this.state.fullscreenimageviewdialogimagerotationangle.toString();
            angle = 'rotate(' + angle + 'deg)'
            document.getElementById('fullscreenimageviewdialog1image').style.transform = angle;
        })
    }

    ImageUploader = (e) => {
        this.Display_images = () => {
            var img_ = []
            for (var i in this.state.images) {
                console.log(i)
                img_.push(
                    <div className="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img iid={i} src={this.state.images[i]} className="card-img-top" onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog1').removeAttr('hidden')
                            $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i className="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}> Delete</i>
                    </div>
                )
            }
            return (
                <div className="d-flex flex-wrap align-items-center">
                    {img_}
                </div>
            )
        }
        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" className="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" className="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div className="card" >
                <div className="card-body">
                    <h5 className="card-title">Add Images</h5>
                    <this.Display_images />
                    {image_delete_help}
                    <button type="button" className="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }

    render() {
        return [
            <form>
                <div className="form-group">
                    <label for="titleInput"><b>Title</b></label>
                    <input type="text" className="form-control" id="titleInput" name="title" value={this.state.title} onChange={this.handle_change} />
                </div>
                <div className="form-group">
                    <label for="descriptionInput"><b>Description</b></label>
                    <textarea className="form-control" id="descriptionInput" rows="3" name="description" value={this.state.description} onChange={this.handle_change}></textarea>
                </div>
                <div className="form-group">
                    <label for="descriptionInput"><b>Priority</b></label>
                    <div>
                        <div className="btn-group" role="group">
                            <button type="button" id="priority_buttons_urgent" name="Urgent" className="btn btn-secondary priority-buttons border-right-0" onClick={this.priority_button_click_handle}>Urgent</button>
                            <button type="button" id="priority_buttons_high" name="High" className="btn btn-secondary priority-buttons border-left-0 border-right-0" onClick={this.priority_button_click_handle}>High</button>
                            <button type="button" id="priority_buttons_medium" name="Medium" className="btn btn-secondary priority-buttons border-left-0 border-right-0" onClick={this.priority_button_click_handle}>Medium</button>
                            <button type="button" id="priority_buttons_low" name="Low" className="btn btn-secondary priority-buttons border-left-0" onClick={this.priority_button_click_handle}>Low</button>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label for="beforeDate"><b>Before</b></label>
                    <div>
                        <DatePicker
                            id="beforeDate"
                            selected={this.state.before_date}
                            onChange={(date) => {
                                this.setState({ before_date: date });
                            }}
                            dateFormat="dd/MM/yyyy p"
                            className="form-control w-100"
                            showTimeSelect
                        />
                        {<this.Before_date_reset />}
                    </div>
                </div>
                <div className="form-group">
                    <label for="scheduleDate"><b>Schedule</b></label>
                    <div>
                        <DatePicker
                            id="scheduleDate"
                            selected={this.state.schedule_date}
                            onChange={(date) => {
                                this.setState({ schedule_date: date });
                            }}
                            dateFormat="dd/MM/yyyy p"
                            className="form-control w-100"
                            showTimeSelect
                        />
                        {<this.Schedule_date_reset />}
                    </div>
                </div>
                <div className="form-group">
                    <label for="descriptionTo"><b>Assigned To</b></label>
                    {<this.To />}
                </div>
                <div className="form-group">
                    <this.ImageUploader />
                </div>
                {/* <this.TaskPostButton /> */}
                <div className="form-group">
                    <this.Status />
                </div>
                <button type="button" id="postTaskButton" className="btn btn-secondary m-2" onClick={this.handle_tasks_post} disabled={this.state.isPostLoading}><i className="fa fa-circle-o-notch fa-spin mr-1" hidden={!this.state.isPostLoading}></i>Create</button>
                <button type="button" id="clearTaskButton" className="btn btn-danger m-2" onClick={() => {
                    this.setState({ title: "", description: "", priority: "", to_id: "", to_search: "", response: {}, asyncsearch: { isLoading: false, options: [] }, schedule_date: "", before_date: "", images: [] });
                }}>Clear</button>
            </form>,
            <this.FullScreenImageView />
        ];
    }
}

class CreateNew extends React.Component {
    constructor(props) {
        super()

        // set to
        var to = ""
        if (TP === "owner") { 
            to = null
        } 
        

        this.state = {
            priority: "Medium",
            before: "",
            schedule: "",
            to: to,
            images: [],
            isLoading: false,
            title: "",
            description: "",

            mainDisablingClass: {

            },

            titleClass: "form-control",
            descriptionClass: "form-control",
            beforeClass: "form-control",
            scheduleClass: "form-control",
            assignedToClass: "form-control"
        }
    }

    handle_search = (query) => {
        this.setState({ asyncsearch: { isLoading: true } })
        const that = this;
        axios.get(URL + 'users', {
            params: {
                s: query
            },
            headers: { Authorization: ACCT }
        })
            .then(function (response) {
                that.setState({ asyncsearch: { isLoading: false, options: response["data"]["payload"] } })
            })
    }

    reset_fields = () => {

        var to = ""
        if (TP === "owner") { 
            to = null
        } 

        this.setState({
            priority: "Medium",
            before: "",
            schedule: "",
            to: to,
            images: [],
            isLoading: false,
            title: "",
            description: "",
        })
        const instance = this.assigned_to_ref.getInstance()
        instance.clear()
    }

    handle_create_task_response = (data) => {

        this.setState({
            mainDisablingClass: {

            },
            isLoading: false
        })

        if (!("_status" in data) || !("payload" in data)) {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Create Task",
                message: "Server side error while creating task.",
                date: new Date(),
                isError: true
            })
            return
        }

        if (data["_status"] !== "success") {
            toastsDispatcher.dispatch({
                actionType: 'add-toast',
                title: "Create Task",
                message: "Failed to Create Task. " + data["payload"][0]["task"],
                date: new Date(),
                isError: true
            })
            return
        }

        fullDialogDispatcher.dispatch({
            actionType: "show-dialog",
            message: <div className="container w-100">
                <div className="mt-2 mb-2">
                    <h4>Create Task</h4>
                    <p>Task created successfully.</p>
                </div>
            </div>,
            isSuccess: true
        })

        this.reset_fields()

        return


    }

    handle_create_task = () => {

        // sanitize data
        var title = this.state.title.trim()
        var description = this.state.description.trim()

        this.setState({
            title: title,
            description: description
        })

        // check error
        var isError = false

        if (title.length === 0) {
            isError = true
            this.setState({ titleClass: "form-control is-invalid" })
        } else {
            this.setState({ titleClass: "form-control" })
        }

        if (description.length === 0) {
            isError = true
            this.setState({ descriptionClass: "form-control is-invalid" })
        } else {
            this.setState({ descriptionClass: "form-control" })
        }

        if (this.state.to === null) {
            isError = true
            this.setState({ assignedToClass: "form-control is-invalid" })
        } else {
            this.setState({ assignedToClass: "form-control" })
        }

        if (isError) {
            return
        }

        ///


        // prepare dates

        var before_date = ""
        if (this.state.before.length > 0) {
            before_date = moment(this.state.before)
            
        }
        var schedule_date = ""
        if (this.state.schedule.length > 0) {
            schedule_date = moment(this.state.schedule)
        }
        

        this.setState({
            mainDisablingClass: {
                opacity: 0.5,
                pointerEvents: 'none',
            },
            isLoading: true
        })

        const that = this;

        var tmp_images = []

        if (this.state.images.length > 0) {
            for (var i in this.state.images) {
                tmp_images.push(
                    {
                        "image": this.state.images[i].split(",")[1],
                        "alt": ""
                    }
                )
            }
        }

        // prepare to
        var to = ""
        if (TP === "owner") { 
            to = this.state.to._id.$oid
        }
        axios.post(URL + "t", {
            title: this.state.title,
            description: this.state.description,
            priority: this.state.priority,
            to: to,
            scheduledate: schedule_date,
            beforedate: before_date,
            images: tmp_images
        }, {
            headers: {
                Authorization: ACCT
            }
        })
            .then(function (response) {
                that.handle_create_task_response(response["data"])
                // that.setState({ response: response["data"], isPostLoading: false })
                // console.log(response["data"])
            })
            .catch(function (error) {

                that.handle_create_task_response({})
                // that.setState({ response: { "_status": "fail", "payload": "" }, isPostLoading: false })

            })





    }
    ImageUploader = (e) => {
        this.Display_images = () => {
            var img_ = []
            for (var i in this.state.images) {
                console.log(i)
                img_.push(
                    <div className="card col-xl-3 col-sm-12 col-lg-3 p-1" style={{ cursor: "pointer" }}>
                        <img iid={i} src={this.state.images[i]} className="card-img-top" onClick={(e) => {
                            this.setState({ fullscreenimageviewdialogimagerotationangle: -90 }, this.rotate_image)
                            document.getElementById("fullscreenimageviewdialog1image").setAttribute("src", e.target.src)
                            $('#fullscreenimageviewdialog1').removeAttr('hidden')
                            $('#fullscreenimageviewdialog1').css("background", "rgba(0,0,0,0.5)")
                        }} />
                        <i className="fas fa-times" iid={i} onClick={(e) => {
                            var iid = e.target.getAttribute('iid');
                            var tmp_images = this.state.images;
                            tmp_images.splice(parseInt(iid), 1)
                            this.setState({ images: tmp_images })
                        }}> Delete</i>
                    </div>
                )
            }
            return (
                <div className="d-flex flex-wrap align-items-center">
                    {img_}
                </div>
            )
        }
        var image_delete_help = ""
        var clear_all_button = ""
        if (this.state.images.length > 0) {
            image_delete_help = <small id="imageupload_help" className="form-text text-muted mt-3">Click on the image to view it fullscreen.</small>
            clear_all_button = <button type="button" className="btn btn-primary m-1" onClick={(e) => {
                this.setState({ images: [] })
            }}>Clear All</button>
        }

        return (
            <div className="card" >
                <div className="card-body">
                    <h5 className="card-title">Add Images</h5>
                    <this.Display_images />
                    {image_delete_help}
                    <button type="button" className="btn btn-primary m-1" onClick={(e) => {
                        document.getElementById('exampleFormControlFile1').click();
                    }}>Add Image(s)</button>
                    {clear_all_button}
                    <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(input) => {
                        const that = this;

                        for (var i = 0; i < input.target.files.length; i++) {
                            if (input.target.files[i]["type"] !== "image/jpeg") {
                                continue;
                            }
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                var tmp_images = that.state.images;
                                tmp_images.push(
                                    e.target.result
                                )
                                that.setState({ images: tmp_images })
                                document.getElementById('exampleFormControlFile1').value = "";

                            }
                            reader.readAsDataURL(input.target.files[i])
                        }
                    }} multiple hidden />
                </div>
            </div>
        )
    }


    render() {

        // priority

        if (this.state.priority === "Urgent") {
            var priority_urgent_class = " priority-buttons-urgent-active "
        } else {
            var priority_urgent_class = ""
        }

        if (this.state.priority === "High") {
            var priority_high_class = " priority-buttons-high-active "
        } else {
            var priority_high_class = ""
        }

        if (this.state.priority === "Medium") {
            var priority_medium_class = " priority-buttons-medium-active "
        } else {
            var priority_medium_class = ""
        }

        if (this.state.priority === "Low") {
            var priority_low_class = " priority-buttons-low-active "
        } else {
            var priority_low_class = ""
        }

        // assigned to 

        var assigned_to = <b>{FN}</b>
        if (TP === "owner") {
            assigned_to = <AsyncTypeahead type="text" placeholder="Search" name="to"
                id="searchInput"
                labelKey={option => `${option.firstname} ${option.lastname} | ${option.username} | ${option.phone}`}
                filterBy={['firstname', 'lastname', 'username']}
                onSearch={this.handle_search}
                {...this.state.asyncsearch}
                maxResults="10"
                ref={(ref) => { this.assigned_to_ref = ref }}
                inputProps={{
                    className: this.state.assignedToClass,
                }}


                renderMenu={(results, menuProps) => (
                    <Menu {...menuProps} className="search_menu" id="search_menu">
                        {results.map((result, index) => (
                            <MenuItem option={result} position={index} className="search_item m-0">
                                <div className="w-100 p-2 search_item" onClick={(e) => {
                                    //   $("#search_menu").hide();
                                    this.setState({ to: result })
                                }}>{`${result.firstname} ${result.lastname} | ${result.username} | ${result.phone}`}</div>
                            </MenuItem>
                        ))}
                    </Menu>
                )}

            />
        }


        // assigned to name
        var assigned_to_name = null
        if (this.state.to !== null) {
            assigned_to_name = <div className="mb-2"><b>{this.state.to.firstname}</b></div>
        }


        // today
        var today = moment().format("YYYY-MM-DDTHH:mm")
        
        return (
            <div style={this.state.mainDisablingClass}>
                <div className="form-group">
                    <label htmlFor="taskCreateTitle"><b>Title</b></label>
                    <input id="taskCreateTitle" type="text" className={this.state.titleClass} value={this.state.title} onChange={(e) => {
                        this.setState({ title: e.target.value })
                    }} />
                    <div id="" class="invalid-feedback">
                        Title cannot be empty.
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="taskCreateDescription"><b>Description</b></label>
                    <textarea id="taskCreateDescription" rows="4" type="text" className={this.state.descriptionClass} value={this.state.description} onChange={(e) => {
                        this.setState({ description: e.target.value })
                    }} />
                    <div id="" class="invalid-feedback">
                        Description cannot be empty.
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="taskCreatePriority"><b>Priority</b></label>
                    <div>
                        <div className="btn-group" role="group" id="taskCreatePriority">
                            <button type="button" id="priority_buttons_urgent" name="Urgent" className={"btn btn-outline-secondary priority-buttons-urgent border-right-0" + priority_urgent_class} onClick={() => {
                                this.setState({ priority: "Urgent" })
                            }}>Urgent</button>
                            <button type="button" id="priority_buttons_high" name="High" className={"btn btn-outline-secondary priority-buttons-high border-left-0 border-right-0" + priority_high_class} onClick={() => {
                                this.setState({ priority: "High" })
                            }}>High</button>
                            <button type="button" id="priority_buttons_medium" name="Medium" className={"btn btn-outline-secondary priority-buttons-medium border-left-0 border-right-0" + priority_medium_class} onClick={() => {
                                this.setState({ priority: "Medium" })
                            }}>Medium</button>
                            <button type="button" id="priority_buttons_low" name="Low" className={"btn btn-outline-secondary priority-buttons-low border-left-0" + priority_low_class} onClick={() => {
                                this.setState({ priority: "Low" })
                            }}>Low</button>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="taskCreateBefore"><b>Before</b></label>
                    <div className="col-md-6 col-xl-4 col-lg-6 col-sm-12 p-0 d-flex flex-row">
                        <input type="datetime-local" id="taskCreateBefore" className="form-control" value={this.state.before} min={today} onChange={(e) => {
                            this.setState({ before: e.target.value })
                        }} />
                        <button className="close ml-2" onClick={() => {
                            this.setState({ before: "" })
                        }}>
                            &times;
                        </button>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="taskCreateSchedule"><b>Schedule</b></label>
                    <div className="col-md-6 col-xl-4 col-lg-6 col-sm-12 p-0 d-flex flex-row">
                        <input type="datetime-local" id="taskCreateSchedule" className="form-control" value={this.state.schedule} min={today} onChange={(e) => {
                            this.setState({ schedule: e.target.value })
                        }} />
                        <button className="close ml-2" onClick={() => {
                            this.setState({ schedule: "" })
                        }}>
                            &times;
                        </button>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="taskCreateAssigendTo"><b>Assigned To</b></label>
                    <div>
                        {assigned_to_name}
                    </div>
                    <div>
                        {assigned_to}
                        <div id="" class="invalid-feedback">
                            Please select someone to assign task to.
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <this.ImageUploader />
                </div>

                <div className="form-group">
                    <button type="button" className="btn btn-primary mr-2" onClick={this.handle_create_task}>
                        <FontAwesomeIcon className="btn-link text-light mr-1" icon={faCircleNotch} spin hidden={!this.state.isLoading} />Create
                    </button>
                    <input type="button" value="Clear" className="btn btn-danger" onClick={this.reset_fields} />
                </div>
            </div>
        )
    }
}


class View extends React.Component {

    render() {
        return (
            <Tasks />
        )
    }


}

class Owner extends React.Component {

    constructor(props) {
        super(props);
        this.open_view = this.open_view.bind(this);
        this.open_create = this.open_create.bind(this);
        this.ViewCreate = this.ViewCreate.bind(this);
        this.state = { open_view_bool: true, open_create_bool: false }
    }

    open_view = (e) => {
        this.setState({ open_view_bool: true, open_create_bool: false })
    }
    open_create = (e) => {
        this.setState({ open_view_bool: false, open_create_bool: true })
    }

    ViewCreate = (e) => {
        if (this.state.open_view_bool) {
            $('#view_button').css("background-image", "linear-gradient(white, white)");
            $('#create_button').css("color", "#ced4da");
            $('#view_button').css("color", "inherit");
            $('#create_button').css("background-image", "linear-gradient(white, white)");
            $('#create_button').css("border", "0px")
            $('#create_button').css("border-bottom", "2px #ced4da solid")
            $('#view_button').css("border", "2px #ced4da solid")
            $('#view_button').css("border-bottom", "0px")
            return (
                <View />
            );
        }
        if (this.state.open_create_bool) {
            $('#view_button').css("background-image", "linear-gradient(white, white)");
            $('#view_button').css("color", "#ced4da");
            $('#create_button').css("color", "inherit");
            $('#create_button').css("background-image", "linear-gradient(white, white)");
            $('#view_button').css("border", "0px")
            $('#view_button').css("border-bottom", "2px #ced4da solid")
            $('#create_button').css("border", "2px #ced4da solid")
            $('#create_button').css("border-bottom", "0px")
            return (
                <CreateNew />
            );
        }
        return "";
    }

    render() {


        return (
            <div className="container mt-3 mb-3">
                <div className="d-flex justify-content-between">
                    <div onClick={this.open_view} id="view_button" style={{ backgroundImage: "linear-gradient(white, white)", border: "2px #ced4da solid", borderBottom: "0px" }} className="container col-xl-6 col-lg-6 col-xs-6 col-sm-6 custom_button text-center ">
                        <h4 className="m-0">View</h4>
                    </div>
                    <div onClick={this.open_create} id="create_button" style={{ borderBottom: "2px #ced4da solid", color: "#ced4da" }} className="container col-xl-6 col-lg-6 col-xs-6 col-sm-6 custom_button text-center">
                        <h4>Create</h4>
                    </div>
                </div>
                <div>
                    <this.ViewCreate />
                </div>
            </div>
        );

    }
}

export default Owner;