import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie';
import URL from './URL';
import {Toasts} from './Toasts';
import {FullDialog} from './FullDialog';

const axios = require('axios').default;

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var ACCT = Cookies.get('ACCT');
var FN = Cookies.get('FN');
var U = Cookies.get('U');

const settings_icon = <FontAwesomeIcon icon={faCog} size="lg" />

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.handle_logout = this.handle_logout.bind(this);
    }

    handle_logout = (e) => {
        axios.post(URL + 'logout', {
        },
            {
                headers: { Authorization: ACCT }
            }
        )
            .then(function (response) {
                // console.log(response['data']);
                Cookies.remove('ACCT');
                Cookies.remove('U');
                Cookies.remove('TP');
                Cookies.remove('FN');
                window.location.reload();
            })
            .catch(function (error) {
                console.log(error)
            })

    }

    render() {
        var logout_settings_button = []

        if (ACCT != null) {
            logout_settings_button.push(

                <div key={`logoutSettingButtonKey`} className="dropdown d-flex align-items-center header_settings_button" id="settings-logout-menubutton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span>{U}</span>&nbsp;{settings_icon}
                </div>

            )
        }
        return [
            <div className="container header">
                <div className="d-flex justify-content-between">
                    <span>
                        <h2>Tasks</h2>
                    </span>
                    {logout_settings_button}
                    <div className="dropdown-menu" aria-labelledby="settings-logout-menubutton">
                        <a className="dropdown-item custom_button" href="#">Settings</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item custom_button" href="#" onClick={this.handle_logout}>Logout</a>
                    </div>
                </div>
            </div>,
            <Toasts />,
            <FullDialog />
        ]
    }
}

export default Header;