import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTimesCircle,faInfoCircle,faCheckCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Dispatcher = require('flux').Dispatcher;

var fullDialogDispatcher = new Dispatcher()


class FullDialog extends React.Component {
    constructor(props) {
        super()
        this.state = {
            show: false,
            title: "",
            message: "",
            isError: false,
            isInfo: false,
            isSuccess: false,
            isLoading: false,
            closeDisable:false,
            closeOnOutsideClick: false,
            loadingMessage:""
        }
    }

    componentDidMount() {
        const that = this
        fullDialogDispatcher.register(function (payload) {
            if (payload.actionType === "show-dialog") {
                that.setState({
                    show: true,
                    title: payload.title,
                    message: payload.message,
                    isError: payload.isError,
                    isInfo: payload.isInfo,
                    isSuccess: payload.isSuccess,
                    isLoading:payload.isLoading,
                    closeDisable:payload.closeDisable,
                    closeOnOutsideClick: payload.closeOnOutsideClick,
                    loadingMessage:payload.loadingMessage
                })
            }
        })
    }


    render() {
        if (!this.state.show) {
            return (
                null
            )
        }


        // get message icon
        var messageIcon = null
        if (this.state.isError) {
            messageIcon = <FontAwesomeIcon className="text-danger" icon={faTimesCircle} title="Error" />
        }
        if (this.state.isInfo) {
            messageIcon = <FontAwesomeIcon className="text-info" icon={faInfoCircle} title="Error" />    
        }
        if (this.state.isSuccess) {
            messageIcon = <FontAwesomeIcon className="text-success" icon={faCheckCircle} title="Error" />  
        }
        //

        var loadingIcon = null 

        if (this.state.isLoading) {
            loadingIcon = <div className="d-flex justify-content-start align-items-center">
                <FontAwesomeIcon className="mr-1" icon={faCircleNotch} title="Loading" spin /><span>{this.state.loadingMessage}</span>
            </div>
        }
        return (
            <div className="d-flex justify-content-center align-items-center " style={{
                position: 'fixed',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                
                background: "rgba(0, 0, 0, 0.55)",
                minHeight: "100vh",
                // minWidth: "100vh",
                zIndex: 1000
            }} onClick={(e) => {
                if (this.state.closeOnOutsideClick) {
                    this.setState({ show: false })

                }
            }} hidden>

                <div className="card p-4 col-md-5 col-lg-4 col-xl-4 xl-sm-10 m-2" style={{
                    // position: 'absolute',
                    // top: '100',
                    // left: '100',
                    // background:"pink",
                    // color:"white"
                }} onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <span>
                            <h4>
                                {this.state.title}
                            </h4>
                        </span>
                        <FontAwesomeIcon icon={faTimes} title="Close" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.setState({ show: false })
                        }} hidden={this.state.closeDisable}/>
                    </div>
                    <h3>
                        {messageIcon}
                    </h3>
                    {loadingIcon}
                    {this.state.message}
                </div>
            </div>
        )
    }
}


export {
    FullDialog,
    fullDialogDispatcher
}