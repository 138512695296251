import React from 'react';
import './App.css';

import Header from './Header';
import Tasks from './Tasks';
import Owner from './Owner';
import Auth from './Auth';
import Cookies from 'js-cookie';
import URL from './URL';

var TP = Cookies.get('TP')
var userInfo = Cookies.get('U');
var ACCT = Cookies.get('ACCT')


const axios = require('axios').default;


class App extends React.Component {


  constructor(props) {
    super(props)

    this.fetchLocationTimerFlag = false
  }

  componentDidMount() {
    this.initTimer()
    if (TP != "owner" && TP != undefined && TP != null && ACCT != undefined && ACCT != null) {
      this.fetchLocation()
    }
  }

  componentWillUnmount() {
    this.deinitTimer()
  }

  initTimer() {
    if (TP != "owner" && TP != undefined && TP != null && ACCT != undefined && ACCT != null) {
      this.timer = setInterval(() => this.fetchLocation(), 15000);
    }
  }

  deinitTimer() {
    if (TP != "owner") {
      clearInterval(this.timer)
      this.timer = null; // here...
    }
  }

  postLocation = (location) => {

    const that = this;
    console.log("posting: ", location.coords)
    axios.post(URL + "l", {
      lat: location.coords.latitude,
      long: location.coords.longitude,
      alt: location.coords.altitude,
      acc: location.coords.accuracy,
      altacc: location.coords.altitudeAccuracy,
      head: location.coords.heading,
      sped: location.coords.speed
    }, {
      headers: { Authorization: ACCT },
    })
      .then(function (response) {
        that.setState({ "selected_task": response["data"]["payload"], "task_selected_bool": true })
      })
  }

  fetchLocation = () => {

    if (this.fetchLocationTimerFlag) {
      clearInterval(this.timer)
      this.timer = null; // here...
      return
    }


    this.fetchLocationTimerFlag = true


    if (!navigator.geolocation) {

      alert('Geolocation is not supported by your browser. Please contact the owner of the business.');
      this.fetchLocationTimerFlag = false
      clearInterval(this.timer)
      this.timer = null; // here...
    } else {

      navigator.geolocation.getCurrentPosition((pos) => {

        this.postLocation(pos)
        this.fetchLocationTimerFlag = false
        if (this.timer == null) {
          this.timer = setInterval(() => this.fetchLocation(), 15000);
        }
      }, (error) => {
        alert(error.message)

        clearInterval(this.timer)
        this.fetchLocationTimerFlag = false
        this.timer = null; // here...
        if (this.timer == null) {
          this.timer = setInterval(() => this.fetchLocation(), 15000);
        }
        this.fetchLocation()
      });
    }
  }

  render() {
    if (ACCT != null) {
      if (TP === "owner") {
        return (
          <div>
            <Header />
            <Owner />
          </div>
        )
      }
      else {
        return (
          <div>
            <Header />
            <Owner />
          </div>
        )
      }
    }
    else {
      return (
        <div>
          <Header />
          <Auth />
        </div>
      )
    }
  }
}

export default App;
