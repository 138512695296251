import React from 'react';
import Cookies from 'js-cookie';
import URL from './URL';

const axios = require('axios').default;

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = { "username": "", "password": "", "data": {}}
        this.handle_login = this.handle_login.bind(this);
        this.handle_change = this.handle_change.bind(this);
        this.handle_login_success = this.handle_login_success.bind(this);
        this.Login_alert = this.Login_alert.bind(this);
    }

    Login_alert = (e) => {
        var alert = this.state.alert;
        var data = this.state.data;
        if ("payload" in data) {
            if ("_status" in data) {
                
                if (data["_status"] === "success") {
                    return (
                        <div className="alert alert-success mt-3" role="alert">
                            Login Successful.
                        </div>
                    )
                }
                else
                {
                    return (
                        <div className="alert alert-danger mt-3" role="alert">
                            Failed To Login.
                            {/* {data["payload"]} */}
                        </div>
                    )
                } 
            }
        }
        return ("")
    }

    handle_login_success = (e) => {
        const data = this.state.data
        if ("_status" in data) {
            if (data["_status"] === "success") {
                Cookies.set('ACCT', data['payload'])
                Cookies.set('TP', data['account_type'])
                Cookies.set('U', data['username'])
                Cookies.set('FN',data['fn'])
                window.location.reload();
            }
        }
    }

    handle_login = (e) => {
        const that = this;
        axios.post(URL + 'login', {
            username: this.state.username,
            password: this.state.password,
        })
            .then(function (response) {
                that.setState({ "data": response["data"] }, that.handle_login_success)
            })
    }

    handle_change = (e) => {
        var _name = e.target.name;
        var _val = e.target.value;
        
        var tmp_state = this.state;
        tmp_state[_name] = _val
        this.setState(tmp_state)
    }

    render() {
        return (
            <div className="container">
                <form>
                    <h4>Login</h4>
                    <div className="form-group">
                        <label htmlFor="loginUsername">Username</label>
                        <input type="text" className="form-control" id="loginUsername" name="username" aria-describedby="emailHelp" value={this.state.username} onChange={this.handle_change} />
                        {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    <div className="form-group">
                        <label htmlFor="loginPassword">Password</label>
                        <input type="password" className="form-control" id="loginPassword" name="password" value={this.state.password} onChange={this.handle_change} />
                    </div>
                    <button type="button" className="btn btn-primary" onClick={this.handle_login}>Login</button>
                    <div className="form-group">
                        <this.Login_alert />
                    </div>
                </form>
            </div>
        );
    }
}


class Auth extends React.Component {
    render() {
        return (
            <div className="container mt-3 mb-3">
                <Login />
            </div>
        );
    }
}

export default Auth;